<template>
	<div class="page flex-col">
		<div class="section_1 align-center flex-col" :class="startX<=600?'wid600':''">
			<div class="box_1 flex-col">
				<div class="" v-if="startX<=600">
					<head-top :type="8" :startX="startX"></head-top>
					<!-- <img class="image1"
						src="./assets/img/pslso4n3c9otew86wfeegixpioezy0rieye4658923-24c4-4381-8012-0beab581b3ba.png"
						alt=""> -->
					<div class="image1" @click.stop="toIndex(dataMain.banner.picture[0])">
						<img v-if="dataMain.banner.audio_type==0" :src="dataMain.banner.picture[0].abs_url" alt=""
							style="width: 100%;height:100%">
						<video v-else style="width: 100%;height:100%" :src="dataMain.banner.picture[0].abs_url"></video>
					</div>
					<!-- <el-carousel height="100%" class="image1">
						<el-carousel-item v-for="item in dataMain.banner.picture" :key="item.id" @click.stop="toIndex(item)">
							<img v-if="dataMain.banner.audio_type==0" :src="item.abs_url" alt=""
								style="width: 100%;height:100%">
							<video v-else style="width: 100%;height:100%" :src="item.abs_url"></video>
						</el-carousel-item>
					</el-carousel> -->
				</div>
				<div class="block_1 flex-col" v-else>
					<head-top :type="8" :startX="startX"></head-top>
					<div class="section1" @click.stop="toIndex(dataMain.banner.picture[0])">
						<img v-if="dataMain.banner.audio_type==0" :src="dataMain.banner.picture[0].abs_url" alt=""
							style="width: 100%;height:100%">
						<video v-else style="width: 100%;height:100%" :src="dataMain.banner.picture[0].abs_url"></video>
					</div>
					<!-- <el-carousel height="100%" class="section1">
						<el-carousel-item v-for="item in dataMain.banner.picture" :key="item.id" @click.stop="toIndex(item)">
							<img v-if="dataMain.banner.audio_type==0" :src="item.abs_url" alt=""
								style="width: 100%;height:100%">
							<video v-else style="width: 100%;height:100%" :src="item.abs_url"></video>
						</el-carousel-item>
					</el-carousel> -->
				</div>
				<div class="text-wrapper_1" id="lianXiT">
					<span class="text_10">C</span>
					<span class="text_11">ONTACT</span>
					<span class="text_12">U</span>
					<span class="text_13">S</span>
				</div>
			</div>
			<div class="box_2 flex-col">
				<span class="text_14"  id="lianXiN">联系我们</span>
				<div class="box_3 flex-row justify-between">
					<div class="group_1 flex-col" id="lianXi">
						<span class="text_15">{{dataMain.about.title}}</span>
						<!-- <span class="text_16">天津市金涞精密机械有限公司</span> -->
						<div class="image_3"></div>
						<!-- <img referrerpolicy="no-referrer"
							src="./assets/img/ps99sab6hz1t9nek1wk7r8wrctj0da2oqld4f98c61b-ba08-4776-808d-2bf3963d1905.png" /> -->
						<div class="section_2 flex-row">
							<div class="image-text_1 flex-row justify-between">
								<img class="label_1" referrerpolicy="no-referrer"
									src="./assets/img/ps1sxneef3ha7gempyr5xg77xc54dz500pf0ec5cf7-8e16-4fe7-960d-da834c8946dd.png" />
								<span class="text-group_1">{{dataMain.about.tel_name}}：</span>
							</div>
							<div class="paragraph_1">
								<span class="text_17" v-for="(item,index) in dataMain.about.tel"
									:key="index">{{item}}</span>
							</div>
						</div>
						<div class="section_2 flex-row">
							<div class="image-text_1 flex-row justify-between">
								<img class="label_1" referrerpolicy="no-referrer"
									src="./assets/img/psdpaa00iggfqn4ivl1kgous65empbzdio90580138f-87aa-411e-9043-a1e2143ac7ef.png" />
								<span class="text-group_1">{{dataMain.about.fax_name}}：</span>
							</div>
							<div class="paragraph_1">
								<span class="text_17" v-for="(item,index) in dataMain.about.fax"
									:key="index">{{item}}</span>
							</div>
						</div>
						<div class="section_2 flex-row" style="margin-top: 20px;">
							<div class="image-text_1 flex-row justify-between">
								<img class="label_1" referrerpolicy="no-referrer" src="../../assets/images/chool.png" />
								<span class="text-group_1">{{dataMain.about.address_name}}：</span>
							</div>
							<div class="paragraph_1">
								<span class="text_17">{{dataMain.about.address}}</span>
							</div>
						</div>
					</div>
					<div class="group_2 flex-col" id="container">
						<!-- <img class="image_4" referrerpolicy="no-referrer"
							src="./assets/img/ps4zvitgzcuu3y20r0gn9lccf4zl3izjipme0fe7c35-c68c-4f61-9e17-b43359bba679.png" /> -->
					</div>
					<!-- 地图功能模块 -->
					<div class="box_4 flex-col" ref="box">
						<span class="text_19">附近公交/地铁</span>
						<span class="text_20">公交</span>
						<span class="text_21">地铁</span>
						<div class="flex-row align-center" style="width:100%">
							<div class="image-text_3 flex-row justify-center align-center" @click="num=1"
								:style="num==1?'border-bottom:0;':''">
								<img class="thumbnail_2" referrerpolicy="no-referrer"
									src="./assets/img/ps9s8hxtdpowjguq1ty241qgft2gded9aqc94fcdd7-c562-45fa-98e1-d43acc19a6fa.png" />
								<span class="text-group_4">到这里去</span>
							</div>
							<div class="image-text_3 flex-row justify-center align-center" @click="num=2"
								:style="num==2?'border-bottom:0;':''">
								<img class="thumbnail_2" referrerpolicy="no-referrer"
									src="./assets/img/pscf1yhoa8fo138a2qyvoj1kdqvpneaj7c287183c8-560e-450a-9b78-e6d512e3da38.png" />
								<span class="text-group_4">到这里去</span>
							</div>
							<div class="image-text_3 flex-row justify-center align-center" @click="num=3"
								:style="num==3?'border-bottom:0;':''">
								<img class="thumbnail_2" referrerpolicy="no-referrer"
									src="./assets/img/psdgu5lavm6n5b5gu3wn9d7ov6ab3bp8zjf3565ada-212a-4419-8f0a-563b60ce26e4.png" />
								<span class="text-group_4">在附近找</span>
							</div>
						</div>
						<div class="box_6 flex-row">
							<span class="text_22">起点</span>
							<div class="group_3 flex-col">
								<input type="text" name="" id="" class="box_input">
							</div>
							<div class="text-wrapper_2 flex-align-justify"><span class="text_23">公交</span></div>
							<div class="text-wrapper_2 flex-align-justify"><span class="text_23">驾车</span></div>
						</div>
					</div>
				</div>
				<div class="box_9 flex-row justify-around" id="chool">
					<div class="box_10 flex-col" :id="'chool'+index" v-for="(item,index) in dataMain.company" :key="index">
						<span class="text_25">{{item.name}}</span>
						<img class="image_5" referrerpolicy="no-referrer"
							src="./assets/img/psljcfijwp8grtr1zlz9gybg9qw8kcgu2k2fafd9f4-7e73-438e-a117-fea9c3496d8d.png" />
						<div class="group_4 flex-row justify-between">
							<div class="image-text_6 flex-row justify-between">
								<img class="label_3" referrerpolicy="no-referrer"
									src="./assets/img/pski68zwhs9sj1z6enuh04vkubqkh522nba213418-f9e1-400a-a83c-fbff6070bdd3.png" />
								<span class="text-group_7">{{item.tel_name}}：</span>
							</div>
							<span class="text_26" v-for="(item1,index1) in item.tel" :key="index1">{{item1}}</span>
						</div>
						<div class="group_4 flex-row justify-between">
							<div class="image-text_6 flex-row justify-between">
								<img class="label_3" referrerpolicy="no-referrer"
									src="./assets/img/psnwzd19t3l7cfwwokp2z3vg934scusm3ka11f9f4c-2f22-463e-ae70-6e18220f9e4a.png" />
								<span class="text-group_7">{{item.fax_name}}：</span>
							</div>
							<span class="text_26" v-for="(item1,index1) in item.fax" :key="index1">{{item1}}</span>
						</div>
						<div class="group_5 flex-row justify-between">
							<div class="image-text_7 flex-row justify-between">
								<img class="label_4" referrerpolicy="no-referrer" src="../../assets/images/chool1.png" />
								<span class="text-group_8">{{item.address_name}}：</span>
							</div>
							<span class="text_27">{{item.address}}</span>
						</div>
					</div>
				</div>
				<div class="text-wrapper_4" id='liuYanT'>
					<span class="text_37">O</span>
					<span class="text_38">nline</span>
					<span class="text_39">M</span>
					<span class="text_40">essage</span>
				</div>
				<span class="text_41" id='liuYanN'>在线留言</span>
				<!-- <div class="box_15 flex-row justify-between">
					<div class="group_6 flex-col">
						<span class="text_42">请填写您的名称</span>
						<input type="text" class="group_input" />
					</div>
					<div class="group_6 flex-col">
						<span class="text_42">请填写您的名称</span>
						<input type="text" class="group_input" />
					</div>
				</div> -->
				<!-- <div class="box_15 flex-row justify-between">
					<div class="group_6 flex-col">
						<span class="text_42">请填写您的名称</span>
						<input type="text" class="group_input" />
					</div>
					<div class="group_6 flex-col">
						<span class="text_42">请填写您的名称</span>
						<input type="text" class="group_input" />
					</div>
				</div> -->
				<span class="text_47"  id='liuYan0'>请填写留言内容</span>
				<div class="box_17 flex-col" id='liuYan1'>
					<input type="text" class="group_input" v-model="content"/>
				</div>
				<span class="text_47"  id='liuYan2'>请填写您的联系电话</span>
				<div class="box_17 flex-col"  id='liuYan3'>
					<input type="text" class="group_input" v-model="phone"/>
				</div>
				<!-- <span class="text_48">请填写您的简介</span>
				<div class="box_18 flex-col">
					<textarea class="group_input"></textarea>
				</div> -->
				<div class="text-wrapper_8 flex-align-justify"  id='liuYan4' @click="toFeedback">
					<span class="text_49">提交</span>
				</div>
			</div>
		</div>
		<div class="" v-if="startX>600">
			<body-footer></body-footer>
		</div>
		<div class="" v-else>
			<body-footer1></body-footer1>
		</div>
	</div>
</template>
<script>
	import headTop from "../../components/headTop.vue";
	import bodyFooter from "../../components/bodyFooter.vue";
	import bodyFooter1 from "../../components/bodyFooter1.vue";
	import {
		contacts,feedback
	} from "../../util/api";
	import Vue from 'vue'
	import AMapLoader from '@amap/amap-jsapi-loader';
	export default {
		data() {
			return {
				constants: {},
				num: 1,
				startX: 1200,
				dataMain: '',
				map: null,
				content:'',
				phone:''
			};
		},
		components: {
			headTop,
			bodyFooter,
			bodyFooter1
		},
		mounted() {
			this.getDate()

			this.startX = document.body.clientWidth;
			window.onresize = () => {
				return (() => {
					this.startX = document.body.clientWidth;
				})();
			};
			window.addEventListener("scroll", this.scrollHandle, true);
		},
		deforeDestroy(){
			window.removeEventListener('scroll', this.scrollHandle, true);
		},
		watch: {
			startX(val, oldval) {
				if (val != oldval) {
					// console.log(this.startX, "屏幕宽度");
				}
			},
			lang(val, oldval) {
				if (val != oldval) {
					this.getDate()
					// console.log(this.startX, this.widNum, "屏幕宽度");
				}
			},
		},
		computed: {
			lang() {
				return this.$store.getters.getLang;
			},
		},
		methods: {
			getDate() {
				contacts({
					lang: this.lang
				}).then((res) => {
					this.dataMain = res.data
					this.initMap()
					// this.show1 = this.dataMain.news[0].category_id
					// this.title = res.data.header_nav;
				});
			},
			initMap() {
				AMapLoader.load({
					key: "bb0bb7042ffdedecd5c278724e2f1c91", // 申请好的Web端开发者Key，首次调用 load 时必填
					version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
					plugins: [''], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
				}).then((AMap) => {
					let that = this
					this.map = new AMap.Map("container", { //设置地图容器id
						viewMode: "3D", //是否为3D地图模式
						zoom: 5, //初始化地图级别
						center: [that.dataMain.about.lng, that.dataMain.about.lat], //初始化地图中心点位置
					});
					var markerContent = that.$refs.box
					console.log(markerContent);
					var marker = new AMap.Marker({
						position: new AMap.LngLat(that.dataMain.about.lng, that.dataMain.about.lat),
						// 将 html 传给 content
						content: markerContent,
						// 以 icon 的 [center bottom] 为原点
						offset: new AMap.Pixel(-170, -260)
					});
					var viaMarker = new AMap.Marker({
						position: new AMap.LngLat(that.dataMain.about.lng, that.dataMain.about.lat),
						// 将一张图片的地址设置为 icon
						icon: 'http://libc-baolai.uuudoo.com/static/position.png',
						// 设置了 icon 以后，设置 icon 的偏移量，以 icon 的 [center bottom] 为原点
						offset: new AMap.Pixel(-15, -35)
					});
					// 将 markers 添加到地图
					this.map.add([viaMarker, marker]);
				}).catch(e => {
					console.log(e);
				})
			},
			toFeedback(){
				feedback({
					content:this.content,
					contact:this.phone,
				}).then((res) => {
					alert(res.message)
					if(res.flag=="success"){
						this.content=''
						this.phone=''
					}
				});
			},
			toIndex(v) {
				if (v.target_rule == 1) {
					this.$router.push("/");
				} else if (v.target_rule == 2) {
					this.$router.push("/aboutUs");
				} else if (v.target_rule == 3) {
					let id = v.param
					this.$router.push("/news/" + id);
				} else if (v.target_rule == 4) {
					let news_id = v.param
					this.$router.push("/newDetail/" + news_id);
				} else if (v.target_rule == 5) {
					let id = v.param
					console.log(id);
					this.$router.push("/product/" + id);
				} else if (v.target_rule == 6) {
					this.$router.push("/companies");
				} else if (v.target_rule == 7) {
					this.$router.push("/Investor");
				} else if (v.target_rule == 8) {
					this.$router.push("/invite");
				} else if (v.target_rule == 9) {
					this.$router.push("/contact");
				}
			},
			scrollHandle() {
				let lianXiT = this.isInViewPortOfOne(document.getElementById('lianXiT'))
				if (lianXiT) {
					document.getElementById('lianXiT').style.fontSize = '72px'
					document.getElementById('lianXiT').style.opacity = '1'
					document.getElementById('lianXiN').style.fontSize = '40px'
					document.getElementById('lianXiN').style.opacity = '1'
				}else{
					document.getElementById('lianXiT').style.fontSize = '2px'
					document.getElementById('lianXiT').style.opacity = '0'
					document.getElementById('lianXiN').style.fontSize = '2px'
					document.getElementById('lianXiN').style.opacity = '0'
				}
				let lianXi = this.isInViewPortOfOne(document.getElementById('lianXi'))
				if (lianXi) {
					document.getElementById('lianXi').style.transform = 'scale(1)'
				}else{
					document.getElementById('lianXi').style.transform = 'scale(0.7)'
				}
				let chool = this.isInViewPortOfOne(document.getElementById('chool'))
				for(let i =0;i<this.dataMain.company.length;i++){
					let id = 'chool'+i
					if (chool) {
						setTimeout(()=>{
							document.getElementById(id).style.opacity = '1'
						},i*300+300)
					}else{
						document.getElementById(id).style.opacity = '0'
					}
				} 
				let list =['liuYanT','liuYanN',"liuYan0","liuYan1","liuYan2","liuYan3","liuYan4"]
				for(let i =0;i<list.length;i++){
					let id = list[i]
					let liuYan = this.isInViewPortOfOne(document.getElementById(id))
					if (liuYan) {
						setTimeout(()=>{
							document.getElementById(id).style.opacity = '1'
						},i*200+200)
					}else{
						document.getElementById(id).style.opacity = '0'
					}
				} 
			},
			isInViewPortOfOne(el) {
				// viewPortHeight 兼容所有浏览器写法
				const offset = el.getBoundingClientRect();
				const offsetTop = offset.top;
				const offsetBottom = offset.bottom;
				if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
					// console.log('进入可视区域');
					return true
				} else {
					return false
					// console.log('移出可视区域');
				}
			},
			// toIndex() {
			// 	this.$router.push("/");
			// },
			// toUs() {
			// 	this.$router.push("/aboutUs");
			// },
			// toNews() {
			// 	this.$router.push("/news");
			// },
			// toCenter() {
			// 	this.$router.push("/product");
			// },
			// toSub() {
			// 	this.$router.push("/companies");
			// },
			// toRelat() {
			// 	this.$router.push("/Investor");
			// },
			// toRecruit() {
			// 	this.$router.push("/invite");
			// },
			// toContact() {
			// 	this.$router.push("/contact");
			// },
		},
	};
</script>
<style scoped lang="css" src="./assets/index.css" />
<style lang="scss" scoped>
	.wid600 {
		width: 100%;

		.box_1 {
			width: 100%;

			.text-wrapper_1 {
				font-size: 8.667rem;
				line-height: 5.333rem;
				text-transform: uppercase;
				margin-top: 13.333rem;
			}
		}

		.box_2 {
			width: 100%;

			.text_14 {
				width: 100%;
				height: auto;
				font-size: 5rem;
				line-height: 7.333rem;
				margin: 0 auto;
				margin-top: 4rem;
			}

			.box_3 {
				width: 100%;
				height: auto;
				flex-direction: column;
				margin: 0;
				min-height: 0;

				.group_1 {
					width: 100%;
					height: auto;
					padding: 0 7.667rem;

					.text_15 {
						height: auto;
						font-size: 4.333rem;
						line-height: 4.333rem;
					}

					.text_16 {
						height: auto;
						font-size: 4.333rem;
						line-height: 4.333rem;
						margin-top: 4rem;
					}

					.image_3 {
						width: 5rem;
						height: 1rem;
						border-radius: .5rem;
						margin: 0;
						margin-top: 4.6rem;
					}

					.section_2 {
						margin: 0;
						margin-top: 6.67rem;
						width: 100%;
						height: auto;


						.image-text_1 {
							width: auto;
							height: auto;

							// padding-top: 3.333rem;
							// align-items: center;
							.label_1 {
								width: 5.333rem;
								height: 5.333rem;
							}

							.text-group_1 {
								width: auto;
								height: auto;
								margin-top: 0px;
								font-size: 3.667rem;
								line-height: 5.667rem;
								margin-left: 2rem;
							}
						}

						.paragraph_1 {
							height: auto;
							margin: 0px 0 0 4rem;
							font-size: 3.667rem;
							line-height: 5.667rem;
						}

						.text_17 {
							height: auto;
							margin: 0px 0 0 4rem;
							font-size: 3.667rem;
							line-height: 5.667rem;
						}
					}

					.section_3 {
						width: 100%;
						height: auto;
						margin: 0;
						justify-content: flex-start;

						.image-text_2 {
							width: auto;
							height: auto;

							// padding-top: 3.333rem;
							// align-items: center;
							.label_2 {
								width: 5.333rem;
								height: 5.333rem;
							}

							.text-group_2 {
								width: auto;
								height: auto;
								margin-top: 0px;
								font-size: 3.667rem;
								line-height: 5.667rem;
								margin-left: 2rem;
							}
						}

						.text_18 {
							height: auto;
							margin: 0px 0 0 4rem;
							font-size: 3.667rem;
							line-height: 5.667rem;
						}
					}
				}

				.group_2 {
					width: 113rem;
					height: 46.333rem;
					margin: 0 auto;
					margin-top: 8rem;

					.image_4 {
						width: 5.333rem;
						height: 6.667rem;
						margin: 29rem 0 0 62.333rem;
					}

					.box_4 {
						width: 38.667rem;
						height: auto;
						left: 46rem;
						padding: 2.333rem 3rem;

						.text_19 {
							height: auto;
							font-size: 2rem;
							margin-left: 0;
							line-height: 2rem;
						}

						.text_20 {
							height: auto;
							margin-top: 1.333rem;
							font-size: 1.5rem;
							line-height: 1.5rem;
							margin-left: 0;
						}

						.text_21 {
							height: auto;
							margin-top: 1.333rem;
							margin-bottom: 1rem;
							font-size: 1.5rem;
							line-height: 1.5rem;
							margin-left: 0;
						}

						.image-text_3 {
							width: 36%;

							height: 4rem;
							border: 0.333rem solid rgba(238, 238, 238, 1);

							.thumbnail_2 {
								width: 1.333rem;
								height: 1.667rem;
							}

							.text-group_4 {
								font-size: 1.5rem;
								line-height: 1.5rem;
								margin-left: 0.5rem;
							}

							&:nth-child(1) {
								margin-left: -4%;
							}
						}

						.box_6 {
							width: auto;
							margin: 0;
							height: auto;
							margin-bottom: 3.333rem;
							align-items: center;
							padding-top: 1rem;

							.text_22 {
								width: auto;
								height: auto;
								font-size: 1.5rem;
								line-height: 1.5rem;
								margin-top: 0;
							}

							.group_3 {
								width: 18rem;
								height: 3.333rem;
								border: 0.2rem solid #EEEEEE;
								margin-left: 1rem;

								.box_input {
									font-size: 1.5rem;
									line-height: 1.5rem;
								}
							}

							.text-wrapper_2 {
								width: 5rem;
								height: 3.333rem;
								border: 0.2rem solid #EEEEEE;
								margin-left: 0.3rem;

								.text_23 {
									font-size: 1.5rem;
									line-height: 1.5rem;
								}
							}
						}
					}
				}
			}

			.box_9 {
				width: 100%;
				height: auto;
				margin: 10rem 0rem;
				margin-bottom: 0;
				min-width: 300px;
				flex-wrap: nowrap;
				justify-content: flex-start;

				.box_10 {
					width: 62.5rem;
					height: auto;
					padding-bottom: 8rem;
					margin-right: 5rem;

					&:nth-child(2) {
						.image_5 {
							margin: 0.5rem 0 0 5rem;
						}
					}

					&:nth-last-child(1) {
						margin-right: 0rem;
					}

					.text_25 {
						width: auto;
						height: auto;
						margin: 7.5rem 0 0 5rem;
						font-size: 3.333rem;
						line-height: 6rem;
					}

					.image_5 {
						width: 4.333rem;
						height: 1rem;
						border-radius: 0.5rem;
						margin: 4rem 0 0 5rem;
					}

					.group_4 {
						width: auto;
						height: auto;
						margin: 5.667rem 0 0 5rem;
						justify-content: flex-start;
						align-items: center;

						.image-text_6 {
							width: auto;
							height: auto;
							align-items: center;

							.label_3 {
								width: 4rem;
								height: 3.333rem;
							}

							.text-group_7 {
								width: auto;
								height: auto;
								font-size: 2.667rem;
								line-height: 2.667rem;
								margin-top: 0;
								margin-left: 2.333rem;
							}
						}

						.text_26 {
							margin-top: 0;
							width: auto;
							height: auto;
							font-size: 2.667rem;
							line-height: 1.667rem;
						}
					}

					.group_5 {
						width: auto;
						height: auto;
						margin: 3.333rem 0 0 5rem;
						justify-content: flex-start;
						align-items: center;

						.image-text_7 {
							width: auto;
							height: auto;
							align-items: center;

							.label_4 {
								width: 4rem;
								height: 3.333rem;
							}

							.text-group_8 {
								width: auto;
								height: auto;
								font-size: 2.667rem;
								line-height: 2.667rem;
								margin-top: 0;
								margin-left: 2.333rem;
							}
						}

						.text_27 {
							margin-top: 0;
							width: 66%;
							height: auto;
							font-size: 2.667rem;
							line-height: 4.667rem;
							white-space: normal;
						}
					}
				}
			}

			.text-wrapper_4 {
				font-size: 8.667rem;
				line-height: 8.667rem;
				height: auto;
				text-transform: uppercase;
				margin-top: 13.333rem;
			}

			.text_41 {
				margin-top: 4rem;
				font-size: 5rem;
				line-height: 5rem;
			}

			.box_15 {
				width: 88%;
				margin: 0 auto;
				flex-direction: column;

				.group_6 {
					width: 100%;
					margin-top: 6.667rem;

					.text_42 {
						font-size: 4rem;
						line-height: 4rem;
					}

					.group_input {
						width: 100%;
						height: 12rem;
					}
				}
			}

			.text_47 {
				font-size: 4rem;
				line-height: 4rem;
				width: 88%;
				margin: 0 auto;
				margin-top: 6.667rem;
			}

			.box_17 {
				font-size: 4rem;
				line-height: 4rem;
				width: 88%;
				height: 12rem;
				margin: 0 auto;
				margin-top: 3.667rem;
				border: 0;
				border-radius: 0px;

				.group_input {
					height: 100%;
				}
			}

			.text_48 {
				font-size: 4rem;
				line-height: 4rem;
				width: 88%;
				margin: 0 auto;
				margin-top: 6.667rem;
			}

			.box_18 {
				font-size: 4rem;
				line-height: 4rem;
				width: 88%;
				height: 26.667rem;
				margin: 0 auto;
				margin-top: 3.667rem;
				border: 0;
				border-radius: 0px;

				.group_input {
					font-size: 4rem;
					line-height: 5rem;
					width: 100% !important;
					height: 100% !important;
					padding: 3.333rem;
				}
			}

			.text-wrapper_8 {
				width: 40rem;
				height: 11rem;
				border: 0.2rem solid #0390CF;
				margin-top: 10rem;
				margin-bottom: 13.333rem;

				.text_49 {
					font-size: 4.667rem;
					line-height: 5.667rem;
				}
			}
		}
	}
</style>